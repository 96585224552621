import { SelectedGroupServices } from "../selectedService/selectedService"
import { AllServices } from "../allServices/allServices"
import { ShoppingCart } from "../shoppingCart/shoppingCart"
import { Account } from "../account/account"
import { Register } from "../auth/register/register"
import { LoginDialog } from "../auth/login/loginDialog"
import { AccountSettings } from "../auth/accountSettings/accountSettings"
import { OrderHistory } from "../orderHistory/orderHistory"
import { Achievements } from "../achievement/achievements"
import { Payments } from "../payment/payments"
import { MobileHomePage } from "../homePage/mobileHomePage"
import { PointSelection } from "../pointSelection/pointSelection"
import { Route, Routes } from "react-router-dom"
import { Partners } from "../partners/partners"
import { WhereTo } from "../whereTo/whereTo"
import { SelectedPoint } from "../pointSelection/selectedPoint"
import App from "../../App"
import { OrderDetails } from "../orderHistory/orderDetails"
import { AddressBook } from "../addressBook/addressBook"
import { Credits } from "../credits/credits";
import { Info } from "../info/info"
import { Support } from '../support/support';
import AuthRoute from '../../authRoute';
import { BusinessLockerSelection } from "../pointSelection/businessLockerSelection"

function MobileRoutes() {
    return (
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/home" element={<MobileHomePage />} />
            <Route path="/service"
                element={
                    <AuthRoute type="guest" approved>
                        <SelectedGroupServices />
                    </AuthRoute>} />
            <Route path="/cart"
                element={
                    <AuthRoute type="guest" approved>
                        <ShoppingCart />
                    </AuthRoute>} />
            <Route path="/search" element={<AllServices />} />
            <Route path="/account" element={<Account />} />
            <Route path="/register"
                element={
                    <AuthRoute type="onlyNotAuthenticated">
                        <Register />
                    </AuthRoute>} />
            <Route path="/login" element={<LoginDialog open onClose={() => { }} />} />
            <Route path="/achievements" element={<Achievements />} />
            <Route path="/accSettings"
                element={
                    <AuthRoute type="authenticated">
                        <AccountSettings />
                    </AuthRoute>} />
            <Route path="/point/:type" element={<PointSelection />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/whereTo" element={
                <AuthRoute type="authenticated" approved>
                    <WhereTo />
                </AuthRoute>
            } />
            <Route path="/businessLocker" element={
                <AuthRoute type="authenticated" approved>
                    <BusinessLockerSelection />
                </AuthRoute>
            } />
            <Route path="/selectedPoint" element={<SelectedPoint />} />
            <Route path="/orderDetail" element={<OrderDetails />} />
            <Route path="/addressBook" element={
                <AuthRoute type="authenticated">
                    <AddressBook />
                </AuthRoute>} />
            <Route path="/info" element={<Info />} />
            <Route path="/support" element={<Support />} />
            <Route path="/credits"
                element={
                    <AuthRoute type="authenticated" approved>
                        <Credits />
                    </AuthRoute>} />
            <Route path="/paymentMethods"
                element={
                    <AuthRoute type="authenticated" approved>
                        <Payments />
                    </AuthRoute>} />
            <Route path="/orderHistory"
                element={
                    <AuthRoute type="authenticated" approved>
                        <OrderHistory />
                    </AuthRoute>} />
        </Routes>
    )
}

export default MobileRoutes;
