export class InitialClass
{
    private static _instance: InitialClass;

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }

    public isInitialLoad: boolean = false;

    public setInitialLoad(value: boolean) {
        this.isInitialLoad = value;
    }

    public getInitialLoad() {
        return this.isInitialLoad;
    }
}