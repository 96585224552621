import { createRef, useEffect } from "react";
import { Divider, IconButton, ListItemText, ListItem, Grid, Box } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { addToCart } from "../../redux/reducers/cartReducer";
import { roundDoubleNumbers } from "../../helpers/currency"
import { FilteredItemProps } from "../../interfaces/interfaces"
import { ServiceItem } from "orderme-api-integration-client";
import { useAppDispatch } from "../../redux/hooks";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";

export function FilteredItem(props: FilteredItemProps) {
    const dispatch = useAppDispatch();
    const { service } = props;
    const titleRef = createRef<any>()

    const addProductToCart = (service: ServiceItem) => {
        dispatch(addToCart(service));
        if (appStyleMode === AppStyle.Mobile)
            titleRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    }

    useEffect(() => {
    }, []);

    return (
        <Grid>
            <ListItem ref={titleRef}>
                <ListItemText
                    primary={
                        <Grid container
                            direction={{ xs: "column", sm: "row" }}
                            textAlign={{ xs: "center", sm: "left" }}
                            justifyContent="space-between" alignItems="center">
                            <Grid item xs={6} lg={9}>
                                {service.name}
                            </Grid>
                            <Grid item xs={6} lg={3} sx={{ width: { xs: "240px", lg: "auto" } }}>
                                <Grid container direction="row" alignItems="center" justifyContent="right">
                                    {service.originalPrice > service.price && (
                                        <Box color="text.disabled" sx={{ textDecoration: "line-through", mr: "10px" }}>
                                            {roundDoubleNumbers(service.originalPrice)} €
                                        </Box>)
                                    }
                                    <Box>
                                        {roundDoubleNumbers(service.price)} €
                                    </Box>
                                    <IconButton sx={{
                                        '&:hover': {
                                            backgroundColor: "transparent",
                                        }
                                    }}
                                        onClick={() => addProductToCart(service)}>
                                        <AddCircleOutlineIcon sx={{ color: "paulini.orange", height: "35px", width: "35px" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>}
                />
            </ListItem>
            <Divider />
        </Grid>
    );
}