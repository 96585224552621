import { useEffect, useState } from "react"
import { SettingClient } from "../../../helpers/client"
import { useLocation, useNavigate } from "react-router";
import { Box } from "@mui/material";
import { useAppDispatch } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";
import { appStyleMode, basePath } from "../../../helpers/clientConfigs";
import * as externalLogin from "../../../helpers/externalLogin";
import { Spinner } from "../../spinner/spinner";
import { AppStyle } from "../../../helpers/appStyle";
import { LoginBoxStyleMobile, LoginBoxStyleWebsite } from "../login/loginStyles";
import { authenticateFromGoogle } from "../../../redux/reducers/authReducer";
import { GoogleLoginError, GoogleSignInProps } from "../../../interfaces/interfaces";
import { setUrlBeforeLogin } from "../../../helpers/localStorageService";
import { ExternalAuthenticationRequest } from "orderme-api-integration-client";

export function GoogleSignIn(props: GoogleSignInProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('account');
  const [clientId, setClientId] = useState("");
  const settingClient: SettingClient = new SettingClient();
  const [isBusy, setIsBusy] = useState(false);

  const handleGoogleSignIn = async (accessToken: string, state: string, code: string) => {
    const googleState = externalLogin.getGoogleState();
    externalLogin.removeGoogleState();

    var googleToken = externalLogin.getGoogleTokenProps(accessToken);
    if (!accessToken || !code || state !== googleState.state || googleToken?.nonce !== googleState.nonce) {
      if (props.onError) {
        props.onError("Google OAuth signin failed");
      }

      return;
    }

    try {
      const authRequest = { accessToken: accessToken, state: state, code: code } as ExternalAuthenticationRequest;
      await dispatch(authenticateFromGoogle(authRequest)).unwrap();
      if (props.onSignedIn)
        props.onSignedIn();
    }
    catch (err) {
      console.log(err);

      const loginError = err as GoogleLoginError;
      if (loginError?.userGrantRequired) {
        signing(true, googleToken.email);
        return;
      }

      const registerState = externalLogin.getGoogleRegistrationProps(accessToken, state, code);
      navigate(
        '/register',
        {
          state: registerState,
          replace: true
        });
    }
  };

  useEffect(() => {
    if (clientId !== "") {
      return;
    }

    (async () => {
      let setting = await settingClient.get();

      if (!setting?.authentication?.googleClientId) {
        return;
      }

      setClientId(setting.authentication.googleClientId);
    })();
  }, [clientId]);

  useEffect(() => {
    if (props.userGrantRequired) {
      signing(true, props.loginHint);
    }
  }, [props.userGrantRequired]);

  const onSignedIn = async (event: any) => {
    try {
      var data = event.data;

      if (typeof data === 'string') {
        data = JSON.parse(event.data.substring(7));
      }

      window.removeEventListener('message', onSignedIn);

      await handleGoogleSignIn(data.access_token, data.state, data.code);
    }
    finally {
      setIsBusy(false);
    }
  }

  const signing = (userGrantRequired: boolean, loginHint?: string) => {
    setIsBusy(true);

    const state = externalLogin.randomState();
    const nonce = externalLogin.randomNonce();
    externalLogin.saveGoogleState(state, nonce);

    let redirectUrl = encodeURIComponent(basePath + '/signin-google');
    let endpoint = `https://accounts.google.com/o/oauth2/v2/auth` +
      `?response_type=code id_token` +
      `&nonce=${nonce}` +
      `&state=${state}` +
      `&access_type=offline` +
      `&redirect_uri=${redirectUrl}` +
      `&client_id=${clientId}` +
      `&scope=openid profile email`;

    if (userGrantRequired)
      endpoint += `&prompt=consent`;

    if (loginHint)
      endpoint += `&login_hint=${loginHint}`;

    if (appStyleMode === AppStyle.Mobile) {
      window.addEventListener('message', onSignedIn);
      window.open(endpoint, 'oauth:google', '');
    }
    else {
      setUrlBeforeLogin(location.pathname);
      window.location.replace(endpoint);
    }
  }

  return (props.isVisible &&
    <Box sx={appStyleMode === AppStyle.Mobile ? LoginBoxStyleMobile : LoginBoxStyleWebsite}>
      <button disabled={!clientId} className="loginBtn loginBtn--google" onClick={() => signing(false)}>{t('loginGoogle')}</button>
      <Spinner isActive={isBusy} />
    </Box >);
}