import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { AchieveClient } from "../../helpers/client"
import { Linear } from "../progressBars/linear"
import { AchievementType } from "../../interfaces/interfaces"
import { NavBar } from "../appBars/navBar/navBar";
import { AchievementItem } from "./achievementItem";
import { useTranslation } from 'react-i18next';
import { ListStyled } from "./achievementStyles";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";

export function Achievements() {
  let achievementClient: AchieveClient = new AchieveClient();
  const [achievements, setAchievements] = useState<AchievementType[]>();
  const { t } = useTranslation('achievement');
  const { t: tSalesPoint } = useTranslation('salesPoint');

  const getName = (key: string, value: number) => {
    var name: string = "";

    switch (key) {
      case "stores":
        name = tSalesPoint('salesPoints', { count: value });
        break;
      case "smartDrobes":
        name = t('smartDrobes');
        break;
      case "customers":
        name = t('customers');
        break;
      case "items":
        name = t('items');
        break;
      case "companyAgeInYears":
        name = t('companyAgeInYears', { count: value });
        break;
    }

    return name;
  }

  const loadAchievements = () => {
    achievementClient.getLaundryAchievement().then((response) => {

      var achievementArray: AchievementType[] = [];

      for (const [key, value] of Object.entries(response)) {
        var achievement = {
          name: getName(key, value),
          quantity: value
        } as AchievementType;

        achievementArray.push(achievement);
      }

      achievementArray = achievementArray.sort((a, b) => a.quantity - b.quantity)
      setAchievements(achievementArray);
    })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    loadAchievements();
  }, []);

  return (
    <Grid container direction="column" alignItems="center">
      <NavBar barTitle={t('achievements')} />
      {!achievements && <Linear />}
      <BreadcrumbsBar to={"achievements"} sx={{ pl: "16px" }} />
      <ListStyled isWebsite={appStyleMode === AppStyle.Paulini}>
        {achievements?.map((achievement, index) => {
          return (
            <AchievementItem key={index} achievement={achievement} />
          )
        })}
      </ListStyled>
    </Grid>
  );
}