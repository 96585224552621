import { useEffect, useState } from "react"
import { SettingClient } from "../../../helpers/client"
import { useLocation, useNavigate } from "react-router";
import { useAppDispatch } from "../../../redux/hooks";
import { appStyleMode, basePath } from "../../../helpers/clientConfigs";
import * as externalLogin from "../../../helpers/externalLogin";
import { Spinner } from "../../spinner/spinner";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { AppStyle } from "../../../helpers/appStyle";
import { LoginBoxStyleMobile, LoginBoxStyleWebsite } from "../login/loginStyles";
import { authenticateFromFacebook } from "../../../redux/reducers/authReducer";
import { SignInProps } from "../../../interfaces/interfaces";
import { setUrlBeforeLogin } from "../../../helpers/localStorageService";
import { ExternalAuthenticationRequest } from "orderme-api-integration-client";

export function FacebookSignIn(props: SignInProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [clientId, setClientId] = useState("");
  const settingClient: SettingClient = new SettingClient();
  const [isBusy, setIsBusy] = useState(false);
  const { t } = useTranslation('account');

  const handleFacebookSignIn = async (accessToken?: string, state?: string) => {
    const signInState = externalLogin.getFacebookState();
    externalLogin.removeFacebookState();

    if (!accessToken || state !== signInState) {
      if (props.onError) {
        props.onError("Facebook OAuth signIn failed");
      }
      return;
    }

    try {
      const authRequest = { accessToken: accessToken, state: state } as ExternalAuthenticationRequest;
      await dispatch(authenticateFromFacebook(authRequest)).unwrap();

      if (props.onSignedIn)
        props.onSignedIn();
    }
    catch (err) {
      console.log(err);

      const registerState = await externalLogin.getFacebookRegistrationProps(accessToken, state);
      navigate(
        '/register',
        {
          state: registerState,
          replace: true
        }
      );
    }
  };

  useEffect(() => {
    if (clientId !== "") {
      return;
    }

    (async () => {
      let setting = await settingClient.get();

      if (!setting?.authentication?.facebookClientId) {
        return;
      }

      setClientId(setting.authentication.facebookClientId);
    })();
  }, [clientId]);

  const onSignedIn = async (event: any) => {
    try {
      var data = event.data;

      if (typeof data === 'string') {
        data = JSON.parse(event.data.substring(7));
      }

      window.removeEventListener('message', onSignedIn);

      await handleFacebookSignIn(data.access_token, data.state);
    }
    finally {
      setIsBusy(false);
    }
  }

  const signing = () => {
    setIsBusy(true);

    const state = externalLogin.randomState();
    externalLogin.saveFacebookState(state);

    let redirectUrl = encodeURIComponent(basePath + '/signin-facebook');
    let endpoint = 'https://www.facebook.com/v17.0/dialog/oauth' +
      `?response_type=token` +
      `&state=${state}` +
      `&redirect_uri=${redirectUrl}` +
      `&client_id=${clientId}` +
      `&scope=email,public_profile`;

    if (appStyleMode === AppStyle.Mobile) {
      window.addEventListener('message', onSignedIn);
      window.open(endpoint, 'oauth:facebook', '');
    }
    else {
      setUrlBeforeLogin(location.pathname);
      window.location.replace(endpoint);
    }
  }

  return (props.isVisible &&
    <Box sx={appStyleMode === AppStyle.Mobile ? LoginBoxStyleMobile : LoginBoxStyleWebsite}>
      <button disabled={!clientId} className="loginBtn loginBtn--facebook" onClick={signing}>{t('loginFacebook')}</button>
      <Spinner isActive={isBusy} />
    </Box>);
}
