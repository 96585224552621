import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderDestination } from "orderme-api-integration-client";

export interface DeliveryState {
    orderDestination: OrderDestination
}

const initialState = {
    orderDestination: {} as OrderDestination
}

const pickupSlice = createSlice({
    name: 'pickup',
    initialState: initialState,
    reducers: {
        updateDelivery: (state, action: PayloadAction<OrderDestination>) => {
            state.orderDestination = action.payload;
        },
        clearDelivery: () => initialState
    },
})

export const { updateDelivery, clearDelivery } = pickupSlice.actions
export default pickupSlice.reducer