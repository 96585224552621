import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { OrderDestination, SalesPoint, SalesPointType, } from "orderme-api-integration-client";
import { useNavigate, useLocation } from 'react-router-dom';
import { NavBar } from "../appBars/navBar/navBar";
import { updateDelivery } from "../../redux/reducers/pickupReducer";
import { useTranslation } from 'react-i18next';
import { BottomCartBar } from "../appBars/cartBar/bottomCartBar";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import { StickyCartBar } from "../appBars/cartBar/stickyCartBar";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { styled } from '@mui/system';
import { Input, Label } from "../../controls";
import { OrangeButton } from "../../controls/button/buttonStyles";
import { SalePointClient } from "../../helpers/client";
import { useAppDispatch } from "../../redux/hooks";

export const LockerInput = styled(Input)({
    marginTop: "30px",
    marginBottom: "10px",
    width: "100%"
});

export function BusinessLockerSelection() {
    const salePointClient: SalePointClient = new SalePointClient();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [selectedLocker, setSelectedLocker] = useState<SalesPoint>(null);
    const { t } = useTranslation('salesPoint');
    const isPurchase = location.state as boolean;
    const [lockerNo, setLockerNo] = useState('');
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [foundLocker, setFoundLocker] = useState(null);

    useEffect(() => {
        if (!isPurchase)
            navigate(-1);
    }, [isPurchase]);

    const continuePurchase = () => {
        var orderDestination = {
            knownId: selectedLocker.id,
            address: undefined
        } as OrderDestination;

        dispatch(updateDelivery(orderDestination));
        navigate("/paymentMethods")
    }

    const searchBusinessLocker = async () => {
        try {
            setIsBusy(true);
            setFoundLocker(null);
            const code = Number(lockerNo);
            let wardrobe = null;
            if (code) {
                wardrobe = await salePointClient.getByTypeAndCode(code, SalesPointType.Wardrobe);
            }

            if (wardrobe) {
                setSelectedLocker(wardrobe);
                setFoundLocker(true);
            }
            else {
                setSelectedLocker(null);
                setFoundLocker(false);
            }
        }
        catch (error) {
            setSelectedLocker(null);
            setFoundLocker(false);
        }
        finally {
            setIsBusy(false);
        }
    };

    const getLockerInfoText = () => {
        if (!selectedLocker)
            return;

        return selectedLocker.name + " (" + selectedLocker.address + " " + selectedLocker.city + ")";
    }

    return (
        <Grid container direction="column" alignItems="center">
            <NavBar barTitle={t('businessLocker')} />
            <BreadcrumbsBar to={"cart/businessLocker"} />
            <Grid sx={{ maxWidth: "1000px", width: "100%", backgroundColor: "white", borderRadius: "15px" }}>
                <Grid container direction="column" alignItems="center"
                    sx={{ px: "20px" }}>
                    <LockerInput
                        placeholder={t('enterBusinessLockerNo')}
                        onChange={setLockerNo}
                        value={lockerNo}
                    />
                    <Label sx={{ textAlign: 'left', width: "100%", mb: "10px" }} isVisible={foundLocker !== null} type={foundLocker ? 'success' : "error"}
                        content={foundLocker ?
                            t('selectedBusinessLocker', { wardrobe: getLockerInfoText() }) :
                            t('businessLockerNotFound')
                        } />
                    <OrangeButton disabled={isBusy || !lockerNo} variant="contained" onClick={() => searchBusinessLocker()}>
                        {t('search')}
                    </OrangeButton>
                </Grid>
                {appStyleMode === AppStyle.Mobile ?
                    (<BottomCartBar open={() => continuePurchase()} isPurchase={true} isDisabled={selectedLocker === null} />) :
                    (
                        <StickyCartBar
                            sx={{ m: "20px", mt: "40px" }}
                            open={() => continuePurchase()}
                            isPurchase={true}
                            isDisabled={selectedLocker === null} />)
                }
            </Grid>
        </Grid>
    );
}