import { useEffect, useState } from "react";
import { Grid, List } from "@mui/material";
import { BottomCartBar } from "../appBars/cartBar/bottomCartBar";
import { CustomerInfo, ServiceItem } from "orderme-api-integration-client";
import { ServiceClient } from "../../helpers/client"
import { FilteredItem } from "./filteredItem"
import { SearchBar } from "./searchBar"
import { Linear } from "../progressBars/linear"
import latinize from "latinize";
import { NavBar } from "../appBars/navBar/navBar"
import { useTranslation } from "react-i18next";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import { StickyCartBar } from "../appBars/cartBar/stickyCartBar";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';

export function AllServices() {
    const [services, setServices] = useState<ServiceItem[]>();
    const [filteredServices, setFilteredServices] = useState<ServiceItem[]>();
    const [search, setSearch] = useState("");
    const servicesClient: ServiceClient = new ServiceClient();
    const customerInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
    const { t } = useTranslation('services');

    useEffect(() => {
        if (services) {
            setFilterForServices();
        }
    }, [search, services]);

    useEffect(() => {
        loadAllServices();
    }, [customerInfo]);

    const loadAllServices = () => {
        servicesClient.getServices(customerInfo.id).then((response) => {
            distinctServices(response)
        }).catch((err) => console.log(err))
    }

    const distinctServices = (services: ServiceItem[]) => {
        let distinctedServices: ServiceItem[] = [];
        services.filter(function (item) {
            var i = distinctedServices.findIndex(x => (x.name === item.name && x.price === item.price));
            if (i <= -1) {
                distinctedServices.push(item);
            }
            return null;
        });

        setServices(distinctedServices);
    }

    const setFilterForServices = () => {
        const term = latinize(search || '').toLowerCase();
        let filteredServices = services.filter((service) => latinize(service.name).toLowerCase().includes(term));
        setFilteredServices(filteredServices.sort((a, b) => a.name.localeCompare(b.name)));
    }

    const gridStyleWebsite = {
        maxWidth: "1000px",
        minWidth: "300px",
        backgroundColor: "white",
        color: "black",
        borderRadius: "15px"
    } as const;

    const gridStyleMobile = {
        overflow: "visible"
    } as const;

    return (
        <Grid container direction="column" alignItems="center">
            <NavBar barTitle={t('allServices')} />
            {!filteredServices && (<Linear />)}
            <BreadcrumbsBar to={"search"} />
            {filteredServices &&
                (<Grid sx={appStyleMode === AppStyle.Mobile ? gridStyleMobile : gridStyleWebsite} container justifyContent="center" direction="column">
                    <SearchBar setSearch={setSearch} searchValue={search} />
                    <Grid>
                        <List sx={{ overflow: "auto" }}
                            className={appStyleMode === AppStyle.Mobile ? "all-services-list" : null}>
                            {filteredServices.map((service, index) => {
                                return (
                                    <FilteredItem key={index} service={service} />
                                );
                            })}
                        </List>
                    </Grid>
                    {appStyleMode === AppStyle.Mobile ? (<BottomCartBar />) : (<StickyCartBar />)}
                </Grid>)
            }
        </Grid>
    );
}