import { Grid, Card, Typography, } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { RootState } from "../../redux/store";
import { addToCart, removeByOneFromCart } from "../../redux/reducers/cartReducer";
import { ServiceItem } from "orderme-api-integration-client";
import { AddButton, RemoveButton } from "./productCardStyles"
import { roundDoubleNumbers } from "../../helpers/currency";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getServicePrice } from "../../helpers/costCounter";

interface ProductCardProps {
  item: ServiceItem;
  backgroundColor?: string;
  fontSize: number;
  fontColor: string;
  buttonSize: number;
}

export function ProductCard(props: ProductCardProps) {
  const dispatch = useAppDispatch();
  const { item, backgroundColor, fontSize, fontColor, buttonSize } = props;
  const servicesInBasket: ServiceItem[] = useAppSelector((state: RootState) => state.cartState.servicesInBasket);
  const serviceCount: number = servicesInBasket.filter(service => service.id === item.id).length;
  const servicePrice: number = getServicePrice(item, serviceCount);

  const addToBasket = () => {
    dispatch(addToCart(item));
  }

  const removeFromBasket = () => {
    dispatch(removeByOneFromCart(item));
  }

  return (
    <Card sx={{ borderRadius: "10px", height: "auto", marginBottom: "13px", width: "100%", backgroundColor: backgroundColor }}>
      <Grid container direction="row" sx={{ margin: "5px", paddingLeft: "5px", paddingRight: "5px" }} justifyContent="space-between">
        <Grid item xs={6} sx={{ color: fontColor, fontSize: fontSize }}>{item.name}</Grid>
        <Grid item xs={5} sx={{ position: "absolute", right: "0px", marginRight: "40px" }}>
          <Grid sx={{
            opacity: 0.7,
            color: fontColor,
            fontSize: fontSize,
            textDecoration: "line-through",
            display: item.originalPrice - servicePrice === 0 ? "none" : "inline-block",
            marginRight: "5px"
          }}>
            {roundDoubleNumbers(item.originalPrice)}€
          </Grid>
          <Grid sx={{ color: fontColor, fontSize: fontSize, display: "inline-block" }}>{roundDoubleNumbers(servicePrice)} €</Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-around" sx={{ backgroundColor: "white" }}>
        <RemoveButton onClick={() => removeFromBasket()}>
          <RemoveCircleOutlineIcon sx={{ height: buttonSize, width: buttonSize, color: "orange" }} />
        </RemoveButton>
        <Typography sx={{ marginTop: "15px", fontWeight: "bold", fontSize: "25px" }}>
          {serviceCount}
        </Typography>
        <AddButton onClick={() => addToBasket()}>
          <AddCircleOutlineIcon sx={{ height: buttonSize, width: buttonSize, color: "orange" }} />
        </AddButton>
      </Grid>
    </Card>
  );
}