import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IsDisconnected {
    isDisconnected: boolean;
}

const initialState = {
    isDisconnected: false
} as IsDisconnected;

const networkConnectionSlice = createSlice({
    name: 'networkConnection',
    initialState: initialState,
    reducers: {
        setDisconnected: (state, action: PayloadAction<boolean>) => {
            state.isDisconnected = action.payload;
        },
    },
})

export const { setDisconnected } = networkConnectionSlice.actions
export default networkConnectionSlice.reducer