import { useEffect, useState } from "react";
import { Box, Grid, IconButton, InputAdornment, InputBase, MenuItem, Select } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from "@mui/icons-material/Clear";
import { NearestSalesPoint, SalesPointType } from "orderme-api-integration-client";
import { SalePointClient } from "../../helpers/client"
import { NavBar } from "../appBars/navBar/navBar"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FilterCard, FilterLabel } from "./pointSelectionStyles"
import { Linear } from "../progressBars/linear"
import { ChoosePoints } from "./choosePoints"
import { NoChoosePoint } from "./noChoosePoint"
import { BottomCartBar } from "../appBars/cartBar/bottomCartBar";
import { RequestPermissionDialog } from "./requestPermissionDialog";
import latinize from "latinize";
import { useTranslation } from 'react-i18next';
import { AppStyle } from "../../helpers/appStyle";
import { appStyleMode } from "../../helpers/clientConfigs";
import { StickyCartBar } from "../appBars/cartBar/stickyCartBar";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";

enum FilterTypes {
  SalesPoint = "SalesPoint",
  Wardrobe = "Wardrobe",
  All = "All",
  Nearest = "Nearest"
}

declare let window: any;

export function PointSelection() {
  const params = useParams<'type'>();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('salesPoint');
  const salePointClient: SalePointClient = new SalePointClient();
  const [salePoints, setSalePoints] = useState<NearestSalesPoint[]>();
  const [filteredSalePoints, setFilteredSalePoints] = useState<NearestSalesPoint[]>();
  const [selectedType, setSelectedType] = useState<FilterTypes>(FilterTypes.All);
  const [cities, setCities] = useState<string[]>();
  const [selectedCity, setSelectedCity] = useState<string>(FilterTypes.All);
  const [search, setSearch] = useState("");
  const isPurchase = location.state as boolean;
  const [openRequestPermission, setOpenRequestPermission] = useState<boolean>(false);

  useEffect(() => {
    var pageType = params.type as FilterTypes;

    setSelectedType(pageType);
    getNearestPoints();
  }, []);

  const getNearestPoints = () => {
    if (window.cordova) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }
    else {
      navigator.geolocation.getCurrentPosition(function (position) {
        getPointsByLocation(position.coords.longitude, position.coords.latitude);
      }, onError);
    }
  }

  var onSuccess = function (position: any) {
    getPointsByLocation(position.coords.longitude, position.coords.latitude);
  };

  function onError(error: any) {
    console.log(error.message)

    if (error.PERMISSION_DENIED) {
      getPoints();
      //setOpenRequestPermission(true);
    }
  }

  const getPoints = () => {
    salePointClient.getByType(SalesPointType.SalesPoint).then((response) => {
      var points = response.map(point => new NearestSalesPoint(
        {
          value: point,
          distanceInDegrees: undefined,
          distanceInMeters: undefined
        }
      ));

      setSalePoints(points);

      var responseCities = response.map(res => res.city);
      var uniqueCities = responseCities.filter((c, index) => {
        return responseCities.indexOf(c) === index;
      });
      setCities(uniqueCities);
    })
      .catch((err) => {
        console.log(err);
      });
  }

  const getPointsByLocation = (longitude: number, latitude: number) => {
    salePointClient.getByLocation(longitude, latitude).then((response) => {
      console.log(response);
      const filteredByDistance = response
        .filter(point => point.value.type === SalesPointType.SalesPoint)
        .sort((a, b) => a.distanceInMeters - b.distanceInMeters);
      setSalePoints(filteredByDistance);

      var responseCities = response.map(res => res.value.city);
      var uniqueCities = responseCities.filter((c, index) => {
        return responseCities.indexOf(c) === index;
      });
      setCities(uniqueCities);
    })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    filterSalePoints();
  }, [salePoints, selectedCity, selectedType, search]);

  const filterSalePoints = () => {
    const filteredTypes = salePoints?.filter(point => selectedType === FilterTypes.All || point.value.type === selectedType as string);
    const filteredPoints = filteredTypes?.filter(point => selectedCity === FilterTypes.All || point.value.city === selectedCity);
    const term = latinize(search).toLowerCase();

    const searchedDeliveries: NearestSalesPoint[] = filteredPoints?.filter((deliveryDetail) => {
      const { address, name } = deliveryDetail.value;
      return latinize(address || '').toLowerCase().includes(term) ||
        latinize(name || '').toLowerCase().includes(term);
    });

    setFilteredSalePoints(searchedDeliveries);
  }

  const handleSalesPointChange = (e: any) => {
    const value = e.target.value;
    navigate(`/point/${value}`, { replace: true })
    setSelectedType(value);
  }

  const handleCityChange = (e: any) => {
    const value = e.target.value;
    setSelectedCity(value);
  }

  const closeRequestPermission = () => {
    setOpenRequestPermission(false);
    navigate(-1);
  }

  return (
    <Grid container direction="column" alignItems="center">
      <NavBar barTitle={t('salesPoints')} />
      {filteredSalePoints === undefined && (<Linear />)}
      <BreadcrumbsBar to={isPurchase ? "cart/salesPoints" : "salesPoints"} sx={{ pl: "16px" }} />
      {filteredSalePoints && (
        <Box sx={{ maxWidth: "1000px", width: "100%" }}>
          {
            appStyleMode === AppStyle.Mobile &&
            (<Grid sx={{ height: "55px", backgroundColor: "paulini.blue", marginTop: "-10px" }} container direction="row" justifyContent="space-between">
              <Grid>
                <IconButton disabled >
                  <SearchIcon sx={{ color: "white" }} />
                </IconButton>
                <InputBase
                  sx={{ color: "white", width: "160px" }}
                  placeholder={t('search')}
                  onChange={(e) => setSearch(e.target.value)} />
              </Grid>
            </Grid>)
          }
          <>
            <Grid sx={{ ml: "16px", width: "auto" }} container direction="row" justifyContent={isPurchase ? "center" : "space-between"}>
              <FilterCard sx={{ display: isPurchase && "none" }}>
                <FilterLabel>{t('point').toUpperCase()}</FilterLabel>
                <Grid container justifyContent="center" alignItems="center">
                  <Select sx={{ color: "white" }}
                    fullWidth
                    variant="standard"
                    value={selectedType}
                    onChange={handleSalesPointChange}
                    SelectDisplayProps={{
                      style: { display: "flex", justifyContent: "center", paddingLeft: "15px" }
                    }}
                    disableUnderline>
                    <MenuItem value={FilterTypes.All}>{t('all')}</MenuItem>
                    <MenuItem value={FilterTypes.SalesPoint}>{t('salesPoint')}</MenuItem>
                    <MenuItem value={SalesPointType.Wardrobe}>{t('wardrobe')}</MenuItem>
                  </Select>
                </Grid>
              </FilterCard>
              <FilterCard sx={{ marginRight: "16px" }}>
                <FilterLabel>{t('city').toUpperCase()}</FilterLabel>
                <Grid container justifyContent="center" alignItems="center">
                  <Select sx={{ color: "white" }}
                    SelectDisplayProps={{
                      style: { display: "flex", justifyContent: "center" }
                    }}
                    fullWidth
                    variant="standard"
                    value={selectedCity}
                    onChange={handleCityChange}
                    disableUnderline>
                    <MenuItem value={FilterTypes.All}>{t('all')}</MenuItem>
                    {cities?.map((city, index) => {
                      return (<MenuItem key={index} value={city}>{city}</MenuItem>);
                    })}
                  </Select>
                </Grid>
              </FilterCard>
            </Grid>
            {
              appStyleMode === AppStyle.Paulini &&
              (<Grid sx={{ height: "55px", marginTop: "3px" }} container>
                <InputBase
                  fullWidth
                  sx={{
                    color: "black",
                    backgroundColor: "white",
                    mx: "16px",
                    px: "10px",
                    borderRadius: "4px"
                  }}
                  placeholder={t('search')}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  startAdornment={(
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )}
                  endAdornment={(
                    <InputAdornment position="end">
                      <ClearIcon
                        sx={{ visibility: search ? "visible" : "hidden", cursor: "pointer" }}
                        onClick={() => setSearch("")} />
                    </InputAdornment>
                  )}
                />
              </Grid>)
            }
            {
              filteredSalePoints !== undefined && filteredSalePoints.length === 0 ?
                <NoChoosePoint /> :
                <ChoosePoints filteredDeliveryDetails={filteredSalePoints} isPurchase={isPurchase} />
            }
            {
              appStyleMode === AppStyle.Paulini && isPurchase &&
              <StickyCartBar sx={{ mx: "16px" }} isPurchase={isPurchase} isConfirmation={isPurchase}
                open={() => isPurchase ? navigate("/paymentMethods", { replace: true }) : navigate("/cart")} />
            }
          </>
        </Box>
      )
      }
      {
        appStyleMode === AppStyle.Mobile && (
          <BottomCartBar isPurchase={isPurchase} isConfirmation={isPurchase}
            open={() => isPurchase ? navigate("/paymentMethods", { replace: true }) : navigate("/cart")} />)
      }
      <RequestPermissionDialog open={openRequestPermission} onClose={() => closeRequestPermission()} />
    </Grid >
  );
}