import { AllServices } from "../allServices/allServices"
import { ShoppingCart } from "../shoppingCart/shoppingCart"
import { Register } from "../auth/register/register"
import { AccountSettings } from "../auth/accountSettings/accountSettings"
import { OrderHistory } from "../orderHistory/orderHistory"
import { Achievements } from "../achievement/achievements"
import { Payments } from "../payment/payments"
import { PointSelection } from "../pointSelection/pointSelection"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { Partners } from "../partners/partners"
import { WhereTo } from "../whereTo/whereTo"
import { SelectedPoint } from "../pointSelection/selectedPoint"
import { OrderDetails } from "../orderHistory/orderDetails"
import { AddressBook } from "../addressBook/addressBook"
import { Credits } from "../credits/credits";
import AuthRoute from '../../authRoute';
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { WebsiteHomePage } from "../homePage/websiteHomePage"
import { SelectedCategory } from "../categories/selectedCategory"
import { TermsAndConditions } from "../policy/termsAndConditions"
import { reloadTokenFromStorage } from "../../redux/reducers/authReducer"
import { useEffect } from "react"
import { LoginPage } from "../auth/login/loginPage"
import { TokenStorage } from "../../helpers/tokenStorage"
import { RootState } from "../../redux/store"
import { BusinessLockerSelection } from "../pointSelection/businessLockerSelection"
import { AboutUs } from "../aboutUs/aboutUs"
import { PrivacyPolicy } from "../policy/privacyPolicy"
import { NewsList } from "../news/newsList"
import { NewsItem } from "../news/newsItem"
import { Support } from "../support/support"

function PauliniRoutes() {
    var dispatch = useAppDispatch();
    const location = useLocation();
    const accessToken = useAppSelector((state: RootState) => state.authState.accessToken);

    useEffect(() => {
        if (accessToken === TokenStorage.Instance.getAccessToken())
            return;

        console.debug("Access token changed");
        dispatch(reloadTokenFromStorage());
    }, [location, accessToken]);

    return (
        <Routes>
            <Route path="/" element={<WebsiteHomePage />} />
            <Route path="/home" element={<WebsiteHomePage />} />
            <Route path="/cart"
                element={
                    <AuthRoute type="guest" approved>
                        <ShoppingCart />
                    </AuthRoute>} />
            <Route path="/search" element={<AllServices />} />
            <Route path="/register"
                element={
                    <AuthRoute type="onlyNotAuthenticated">
                        <Register />
                    </AuthRoute>} />
            <Route path="/achievements" element={<Achievements />} />
            <Route path="/rules" element={<TermsAndConditions />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/point/:type" element={<PointSelection />} />
            <Route path="/selectedPoint" element={<SelectedPoint />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/news/:id" element={<NewsItem />} />
            <Route path="/news" element={<NewsList />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/businessLocker" element={
                <AuthRoute type="authenticated" approved>
                    <BusinessLockerSelection />
                </AuthRoute>
            } />
            <Route path="/category/:id" element={<SelectedCategory />} />
            <Route path="/whereTo" element={
                <AuthRoute type="authenticated" approved>
                    <WhereTo />
                </AuthRoute>
            } />
            <Route path="/addressBook" element={
                <AuthRoute type="authenticated">
                    <AddressBook />
                </AuthRoute>} />
            <Route path="/accSettings"
                element={
                    <AuthRoute type="authenticated">
                        <AccountSettings />
                    </AuthRoute>} />
            <Route path="/credits" element={<Credits />} />
            <Route path="/paymentMethods"
                element={
                    <AuthRoute type="authenticated" approved>
                        <Payments />
                    </AuthRoute>} />
            <Route path="/orderHistory"
                element={
                    <AuthRoute type="authenticated" approved>
                        <OrderHistory />
                    </AuthRoute>} />
            <Route path="/orderDetail" element={
                <AuthRoute type="authenticated" approved>
                    <OrderDetails />
                </AuthRoute>} />
            <Route path="/login"
                element={
                    <AuthRoute type="onlyNotAuthenticated">
                        <LoginPage />
                    </AuthRoute>} />
            <Route path="/support" element={<Support />} />
            <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
    )
}
export default PauliniRoutes;
