import { Grid, Typography, Card, Divider } from "@mui/material";
import { RootState } from "../../redux/store";
import { CustomerInfo } from "orderme-api-integration-client";
import { roundDoubleNumbers } from "../../helpers/currency"
import { GrayTypography } from "./shoppingCartStyles"
import { cartWorth, getCreditsUsed, getFinalPrice, originalCartWorth, savings } from "../../helpers/costCounter";
import { useTranslation } from 'react-i18next';
import { useAppSelector } from "../../redux/hooks";

export function CartInfo() {
    const userInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
    const userHasCredit: boolean = !userInfo.credit || userInfo.credit === 0;
    const { t } = useTranslation('payment');

    return (
        <Grid sx={{ marginBottom: "30px", width: "100%" }} container>
            <Card sx={{ width: "100%", padding: "10px", borderRadius: "10px" }}>
                <Grid container direction="row" justifyContent="space-between">
                    <GrayTypography>{t('originalCartWorth')} </GrayTypography>
                    <GrayTypography>{roundDoubleNumbers(originalCartWorth())} €</GrayTypography>
                </Grid>
                <Grid sx={{ display: savings() === 0 && "none" }} container direction="row" justifyContent="space-between">
                    <Typography>{t('discount')}</Typography>
                    <Typography>{savings() > 0 ? '-' + roundDoubleNumbers(savings()) : 0} €</Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                    <GrayTypography>{t('cartWorth')} </GrayTypography>
                    <GrayTypography>{roundDoubleNumbers(cartWorth())} €</GrayTypography>
                </Grid>

                <Divider sx={{ margin: "5px" }} />

                <Grid sx={{ display: (!userInfo.credit || userInfo.credit === 0) && "none" }} container direction="row" justifyContent="space-between">
                    <GrayTypography>{t('credits')} </GrayTypography>
                    <GrayTypography>{roundDoubleNumbers(userInfo.credit)} €</GrayTypography>
                </Grid>
                <Grid sx={{ display: userHasCredit && "none" }} container direction="row" justifyContent="space-between">
                    <Typography ></Typography>
                    <Typography >-{roundDoubleNumbers(getCreditsUsed())} €</Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                    <Typography >{t('finalPrice')}</Typography>
                    <Typography >{roundDoubleNumbers(getFinalPrice())} €</Typography>
                </Grid>
                <Divider sx={{ margin: "5px" }} />
                <GrayTypography sx={{ textAlign: "center" }}>{t('deliveryInfo')}</GrayTypography>
            </Card>
        </Grid>
    );
}