import { Link, DialogContent, Dialog, DialogTitle, Grid, Button, Typography, DialogActions, IconButton } from "@mui/material";
import { useNavigate } from "react-router";
import { DialogProps } from "../../interfaces/interfaces"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

export function RequestPointDialog(props: DialogProps) {
  const navigate = useNavigate();
  const { onClose, open } = props;
  const { t } = useTranslation('salesPoint');

  const handleClose = () => {
    onClose();
  };

  const openPoints = () => {
    navigate("/addressBook");
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ backgroundColor: "paulini.blue", color: "white" }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Typography sx={{ fontSize: "22px" }}>
            {t('information')}
          </Typography>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ fontSize: "17px" }} >
          {t('serviceInAnySalesPoint')}
        </Typography>
        <Link sx={{ fontSize: "17px" }} onClick={() => openPoints()}>{t('deliverySalesPoint')}</Link>
      </DialogContent>

      <Button sx={{ backgroundColor: "paulini.orange" }} onClick={() => navigate("/paymentMethods")}>
        <DialogActions>
          <Grid container direction="row" alignItems="center">
            <Typography sx={{ color: "white" }}>
              {t('continue')}
            </Typography>
            <ArrowForwardIosIcon sx={{ color: "white", height: "17px", width: "17px", marginLeft: "15px" }} />
          </Grid>
        </DialogActions>
      </Button>
    </Dialog>
  );
}