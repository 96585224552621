import { createRef, useEffect, useState } from "react";
import { Box, Grid, List } from "@mui/material";
import { SelectedGroupServicesProps, SubCategoriesProps } from "../../interfaces/interfaces"
import { NoElements } from "./noElements"
import { ServiceClient } from "../../helpers/client";
import { ServiceGroup } from "orderme-api-integration-client";
import { useNavigate } from "react-router";
import { ServiceLogoItem } from "../serviceLogoItem/serviceLogoItem"
import { getUserId } from "../../helpers/customerHelper";

export function SubCategories(props: SubCategoriesProps) {
  const navigate = useNavigate();
  const { category, tabIndex } = props;
  const servicesClient: ServiceClient = new ServiceClient();
  const [tabContent, setTabContent] = useState<ServiceGroup[]>();
  const listRef = createRef<any>();

  useEffect(() => {
    if (listRef?.current?.scrollTop !== 0) {
      listRef?.current?.scrollTo(0, 0);
    }
  }, [tabIndex])

  useEffect(() => {
    setCurrentTab(category.id);
  }, [category]);

  const setCurrentTab = (id: string) => {
    servicesClient.getCategoryGroups(id, getUserId())
      .then((response) => {
        setTabContent(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const openServicePage = (service: ServiceGroup) => {
    navigate(
      '/service',
      {
        state: {
          serviceCategory: category,
          serviceGroup: service
        } as SelectedGroupServicesProps
      });
  }

  const body = () => {
    return (
      <Box sx={{ marginTop: "-10px" }}>
        <List ref={listRef} sx={{ overflow: "auto", }} className="categories-list">
          {tabContent.map((service, index) => (
            <Box sx={{ height: (window.innerHeight - 70) / 3, }}>
              <ServiceLogoItem key={index} service={service} isButton={true} open={() => openServicePage(service)} />
            </Box>
          ))}
        </List>
      </Box>
    );
  }

  return (
    <Grid>
      {tabContent !== undefined && tabContent.length === 0 && <NoElements />}
      {tabContent !== undefined && tabContent.length > 0 && body()}
    </Grid>
  );
}
