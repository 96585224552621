import { ServiceItem } from "orderme-api-integration-client";
import { addToCart, removeByOneFromCart } from "../redux/reducers/cartReducer";
import { store } from "../redux/store";
import { ServiceClient } from "./client";
import { getUserId } from "./customerHelper";
import { clearServiceIds, getCartServiceIds } from "./localStorageService";

const servicesClient: ServiceClient = new ServiceClient();

export const addServicesFromStorage = () => {
  var serviceIdsString = getCartServiceIds();

  if (!serviceIdsString) {
    return;
  }

  var serviceIds = serviceIdsString.split(",");

  servicesClient.getServices(getUserId()).then((response) => {
    var cart = store.getState().cartState.servicesInBasket;

    if (cart.length === 0) {
      const services = response.filter(s => serviceIds.includes(s.id));
      clearServiceIds();

      services.forEach(service => {
        var length = serviceIds.filter(s => s === service.id).length;
        for (var i = 0; i < length; i++) {
          store.dispatch(addToCart(service))
        }
      });
    }
  }).catch((err) => console.log(err))
}

export const refreshCartServices = () => {
  var cart = store.getState().cartState.servicesInBasket
  if (cart.length === 0)
    return;

  servicesClient.getServices(getUserId()).then((response) => {
    checkServices(response, cart);
  }).catch((err) => console.log(err))
}

const checkServices = (services: ServiceItem[], cart: ServiceItem[]) => {
  const sameIds = services.filter(s => cart.map(c => c.id).includes(s.id));

  cart.forEach(service => {
    var newValue = sameIds.filter(s => s.id === service.id)[0];
    if (JSON.stringify(service) !== JSON.stringify(newValue)) {
      store.dispatch(removeByOneFromCart(service));
      store.dispatch(addToCart(newValue));
    }
  })
}