import { Grid } from "@mui/material";
import { HomeBar } from "../appBars/homeBar/homeBar"
import { ServiceTabs } from "../serviceTabs/serviceTabs"
import { BottomCartBar } from "../appBars/cartBar/bottomCartBar";

export function MobileHomePage() {
  return (
    <Grid>
      <Grid sx={{ overflow: "hidden" }}>
        <HomeBar />
        <ServiceTabs />
        <BottomCartBar />
      </Grid>
    </Grid>
  );
}