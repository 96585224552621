import { useRef, useState } from "react";
import { Divider, Grid } from "@mui/material";
import { CustomerAddress, CustomerInfo, DestinationAddress, OrderDestination, UpdateAddressesRequest } from "orderme-api-integration-client";
import { CustomerClient } from "../../helpers/client"
import { useNavigate, useLocation } from 'react-router-dom';
import { NavBar } from "../appBars/navBar/navBar";
import { updateDelivery } from "../../redux/reducers/pickupReducer";
import { RootState } from "../../redux/store";
import { assignCustomerDetails } from "../../helpers/customerHelper";
import { MyAddresses } from "./myAddresses";
import { NewAddress } from "./newAddress";
import { AddressBookRef } from "../../interfaces/interfaces";
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { BottomCartBar } from "../appBars/cartBar/bottomCartBar";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import { StickyCartBar } from "../appBars/cartBar/stickyCartBar";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";

export function AddressBook() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [currentAddress, setCurrentAddress] = useState<CustomerAddress>(null);
  const userInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
  const isPurchase = location.state as boolean;
  const customerClient: CustomerClient = new CustomerClient();
  const NewAddressRef = useRef<AddressBookRef>(null);
  const { t } = useTranslation();

  const continuePurchase = () => {
    var destinationAddress = {
      fullAddress: currentAddress.address,
      apartmentNumber: currentAddress.apartmentNumber
    } as DestinationAddress;

    var orderDestination = {
      knownId: undefined,
      address: destinationAddress
    } as OrderDestination;

    dispatch(updateDelivery(orderDestination));
    navigate("/paymentMethods", { replace: true })
  }

  const updateAddresses = (customerAddresses: CustomerAddress[]) => {
    var updateRequest = {
      addresses: customerAddresses
    } as UpdateAddressesRequest;

    customerClient.changeAddresses(userInfo.id, updateRequest).then(response => {
      NewAddressRef.current.clearValues();
      assignCustomerDetails();
    }).catch();
  }

  return (
    <Grid container direction="column" alignItems="center">
      <NavBar barTitle={t('address:addressBook')} />
      <BreadcrumbsBar
        to={isPurchase ? "cart/addressBook" : "addressBook"}
        currentPageName={isPurchase && t("payment:chooseAddress")} />
      <Grid className={appStyleMode === AppStyle.Mobile && "address-book"} sx={{ maxWidth: "1000px", width: "100%", backgroundColor: "white", borderRadius: "15px" }}>
        <MyAddresses
          updateAddresses={updateAddresses}
          setCurrentAddress={setCurrentAddress}
          currentAddress={currentAddress}
          isSelectable={isPurchase} />
        <Divider sx={{ m: "15px", borderWidth: "1px" }} />
        <NewAddress ref={NewAddressRef} updateAddresses={updateAddresses} />
        {appStyleMode === AppStyle.Mobile ?
          (<BottomCartBar open={() => continuePurchase()} isPurchase={isPurchase} isDisabled={!isPurchase || currentAddress === null} />) :
          isPurchase && (
            <StickyCartBar
              sx={{ m: "20px", mt: "40px" }}
              open={() => continuePurchase()}
              isPurchase={isPurchase}
              isDisabled={!isPurchase || currentAddress === null} />)
        }
      </Grid>
    </Grid>
  );
}