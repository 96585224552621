import { Button, Grid, List, ListItem } from "@mui/material";
import { styled } from '@mui/system';
import { BlueButton, ButtonStyled, OrangeButton } from "../../../controls/button/buttonStyles";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

type AccSettingsListProps = {
  isMobile: boolean
}

export const AccSettingsList = styled(List)<AccSettingsListProps>(({ isMobile }) => ({
  overflow: isMobile ? "auto" : null,
  maxHeight: isMobile ? "calc(100vh - 56px)" : null,
  padding: '0px 10px',
  "& > *": {
    marginTop: "17px"
  }
}));

export const PurpleButton = styled(Button)({
  color: "#7e87fc",
  "&:hover": {
    color: "#515cfb"
  }
});

export const CenterListItem = styled(ListItem)({
  display: "flex",
  justifyContent: "center"
});

export const SaveButton = styled(ButtonStyled)(({ theme }) => ({
  backgroundColor: "#1ABC9C",
  "&:hover": {
    backgroundColor: "#159d82"
  },
  [theme.breakpoints.down(420)]: {
    paddingLeft: "10px",
    paddingRight: "10px",
  }
}));

export const CancelButton = styled(BlueButton)(({ theme }) => ({
  [theme.breakpoints.down(420)]: {
    paddingLeft: "10px",
    paddingRight: "10px",
  }
}));

export const ConfirmButton = styled(OrangeButton)({
  width: "100px"
});

export const PasswordRulesGrid = styled(Grid)(({ theme }) => ({
  marginLeft: "50px",
  color: theme.palette.paulini.error,
  "&>p": {
    fontSize: "14px"
  },
  "&>p:first-child": {
    fontWeight: "bold"
  }
}));

export const RevokeIcon = styled(PersonRemoveIcon)({
  cursor: "pointer"
});