import { useEffect, useState } from "react";
import { Grid, IconButton, Box } from "@mui/material";
import { useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { BottomCartBar } from "../appBars/cartBar/bottomCartBar";
import { ServiceLogoItem } from "../serviceLogoItem/serviceLogoItem";
import { ServicesList } from "./servicesList";
import { SelectedGroupServicesProps } from "../../interfaces/interfaces";

export function SelectedGroupServices() {
  const navigate = useNavigate();
  const location = useLocation();
  const [serviceContent, setServiceContent] = useState<SelectedGroupServicesProps>();

  useEffect(() => {
    if (location.state) {
      setServiceContent(location.state as SelectedGroupServicesProps);
    }
    else {
      navigate("/home", { replace: true });
    }
  }, [location.state]);

  return serviceContent && (
    <Grid sx={{ overflow: "hidden" }}>
      <IconButton sx={{ position: "absolute", mt: "5px", left: "0px", zIndex: 1 }} onClick={() => navigate(-1)}>
        <ArrowBackIcon sx={{ color: "white" }} />
      </IconButton>
      <Box sx={{ height: (window.innerHeight - 70) / 3, }}>
        <ServiceLogoItem service={serviceContent.serviceGroup} />
      </Box>
      <ServicesList serviceGroup={serviceContent.serviceGroup} serviceCategory={serviceContent.serviceCategory} />
      <BottomCartBar />
    </Grid>
  );
}